import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function UserDelivery() {

  this.checkValid = checkValid


  this.data = {

    DHL: {
      DHLTrans: false,
      DHLNoTrans: false,
      DHLOrderPrice: false,
    },

    TNT: {
      TNT: false,
      TNTAllCountries: false,
      TNTNoTrans: false,
    },

    expressToNegativeBalance: false,

    FEDEX: {
      FEDEXTrans: false,
      FEDEXNoTrans: false,
      FEDEXPolandWarehouse: false,
    },

    LANDMARK: {
      LANDMARK: false,
      LANDMARKShippingMethod: '',
    },

    NPG: {
      NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: false,
      // LANDMARKShippingMethod: '',
    },

    UPS: {
      UPS_DELIVERY_SERVICE: false,
      // LANDMARKShippingMethod: '',
    },

  }

  // this.validation = {
  //   userName: false,
  //   userPhone: false,
  //   timePickup: false,
  //   arrivalDate: false,
  //   comment: false,
  // }
  //
  // this.validationTranslate = {
  //   userName: '',
  //   userPhone: '',
  //   timePickup: '',
  //   arrivalDate: '',
  //   comment: '',
  // }
  //
  // this.validationTxt = {
  //   userName: false,
  //   userPhone: false,
  //   timePickup: false,
  //   arrivalDate: false,
  //   comment: false,
  // }

}


/*****************   SETTERS   ******************/

UserDelivery.prototype.setUserDeliveryItem = function (val) {

  this.data.DHL = {
    DHLTrans: parseInt(val.dhl) === 1,
    DHLNoTrans: parseInt(val.dhl_no_transaction) === 1,
    DHLOrderPrice: parseInt(val.change_order_express_price) === 1,
  }

  this.data.TNT = {
    TNT: parseInt(val.tnt) === 1,
    TNTNoTrans: parseInt(val.tnt_no_transaction) === 1,
    TNTAllCountries: parseInt(val.tnt_all_countries) === 1,
  }

  this.data.FEDEX = {
    FEDEXTrans: parseInt(val.fedex) === 1,
    FEDEXNoTrans: parseInt(val.fedex_no_transaction) === 1,
    FEDEXPolandWarehouse: parseInt(val.fedex_use_poland_warehouse) === 1,
  }

  this.data.NPG = {
    NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: val.nova_poshta_global,
  }

  this.data.UPS = {
    UPS_DELIVERY_SERVICE: val.order_ups,
  }

  this.data.expressToNegativeBalance = val.express_to_negative_balance || false

  // this.data.LANDMARK = {
  //   LANDMARK: val.LANDMARK,
  //   LANDMARKShippingMethod: val.LANDMARKShippingMethod,
  // }
}


/*****************   GETTERS   ******************/

UserDelivery.prototype.getPrepareUserDeliveryItem = function () {
  return {
    'dhl': this.data.DHL.DHLTrans ? '1' : '0',
    'dhl_no_transaction': this.data.DHL.DHLNoTrans ? '1' : '0',
    'change_order_express_price': this.data.DHL.DHLOrderPrice ? '1' : '0',

    'tnt': this.data.TNT.TNT ? '1' : '0',
    'tnt_no_transaction': this.data.TNT.TNTNoTrans ? '1' : '0',
    'tnt_all_countries': this.data.TNT.TNTAllCountries ? '1' : '0',

    'fedex': this.data.FEDEX.FEDEXTrans ? '1' : '0',
    'fedex_no_transaction': this.data.FEDEX.FEDEXNoTrans ? '1' : '0',

    // 'LANDMARK': this.data.LANDMARK.LANDMARK,
    // 'LANDMARKShippingMethod': this.data.LANDMARK.LANDMARKShippingMethod,
  }
}

UserDelivery.prototype.getPrepareUserDeliverySettingItem = function () {
  return this.data.expressToNegativeBalance
}

UserDelivery.prototype.getFEDEXPolandWarehouse = function () {
  return this.data.FEDEX.FEDEXPolandWarehouse ? '1' : '0'
}

UserDelivery.prototype.getNOVAPOSHTAGLOBAL = function () {
  return this.data.NPG.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE
}

UserDelivery.prototype.getUPS = function () {
  return this.data.UPS.UPS_DELIVERY_SERVICE
}

/*****************   VALIDATION   ******************/
//
// UserDelivery.prototype.dataValidate = function () {
//
//   let validationItems = {
//     userName: this.data.userName,
//     userPhone: this.data.userPhone,
//     timePickup: this.data.timePickup,
//     arrivalDate: this.data.arrivalDate,
//     comment: this.data.comment,
//   }
//
//   let validationOptions = {
//     userName: {type: ['latin', 'empty']},
//     userPhone: {type: ['phone', 'empty']},
//     timePickup: {type: ['empty']},
//     arrivalDate: {type: ['empty']},
//     comment: {type: ['empty']},
//   }
//
//
//   return this.checkValid(validationItems, validationOptions)
// }
